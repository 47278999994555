const {EventEmitter} = require('./event');

function errorToObject(err)
{
	return {
		name: err.name,
		message: err.message,
		stack: err.stack,
	};
}

function objectToError(obj)
{
	const err = new Error(obj.message);
	err.name = obj.name;
	err.stack = obj.stack;
	return err;
}

class RACProxy {
	internalEventsEmitter = null;
	eventsEmitter = null;
	communicationConnection = null;
	callNumber = 0;
	localProcedures = null;
	remoteProcedures = null;
	localSubscriptions = null;
	remoteSubscriptions = null;

	constructor(communicationConnection) {
		this.internalEventsEmitter = new EventEmitter();
		this.communicationConnection = communicationConnection;

		this.reset();

		this.communicationConnection.setDataReceiverHandler((messageRaw) => {
			const message = JSON.parse(messageRaw);
			//console.log('got', message);
			if (message.type === 'RACProxy.Event') {
				this.trigger(message.event, ...message.args);
			}
		});
		this.communicationConnection.setConnectionOpenHandler(() => {
			this.reinit();
		});
	}

	reset() {
		this.eventsEmitter = new EventEmitter();
		this.callNumber = 0;
		this.localProcedures = new Map();
		this.remoteProcedures = new Map();
		this.localSubscriptions = new Map();
		this.remoteSubscriptions = new Map();
	}

	reinit() {
		this.reset();
		this.triggerOnConnected();
	}

	on(event, handler) {
		//console.log(`on ${event}`);
		this.eventsEmitter.on(event, (...args) => {
			//console.log(`emitted ${event}`, ...args);
			handler(...args);
		});
	}
	once(event) {
		//console.log(`on ${event}`);
		return new Promise((resolve, reject) => {
			this.eventsEmitter.once(event, (...args) => {
				//console.log(`emitted ${event}`, ...args);
				resolve(args);
			});
		});
	}
	each(event) {
		return this.eventsEmitter.each(event);
	}

	emit(event, ...args) {
		this.communicationConnection.send(JSON.stringify({type: 'RACProxy.Event', event: event, args: args}));
	}

	trigger(event, ...args) {
		//console.log(`trigger ${event}`, ...args);
		if (event[0] == ".") {
			// system event
			let prefix;
			if (event.startsWith(prefix = ".procedure<")) {
				if (event.startsWith(prefix = ".procedure<call>.")) {
					const procedure = event.substring(prefix.length);
					if (!this.localProcedures.has(procedure)) {
						const [callNumber, ..._] = args;
						this.emit(`.procedure<result>.${procedure}#${callNumber}`, 'error', errorToObject(new Error(`Procedure '${procedure}' is not registered`)));
						return;
					}
				}else if (event.startsWith(prefix = ".procedure<new>.")) {
					const procedure = event.substring(prefix.length);
					this.remoteProcedures.set(procedure, true);
					this.triggerRemoteProcedureRegistered(procedure);
					return;
				}
			}else if (event.startsWith(prefix = ".subscription<")) {
				if (event.startsWith(prefix = ".subscription<new>.")) {
					const topic = event.substring(prefix.length);
					this.remoteSubscriptions.set(topic, true);
					return;
				}
			}
		}else {
			// standart event
			// pass
		}

		this.eventsEmitter.emit(event, ...args);
	}


	async subscribe(topic, handler) {
		if (!this.localSubscriptions.has(topic)) {
			this.localSubscriptions.set(topic, true);
			if (topic[0] != ".") {
				this.emit(`.subscription<new>.${topic}`);
			}
		}

		this.on(`.subscription.${topic}`, handler);
	}

	async publish(topic, args = []) {
		if (topic[0] != ".") {
			if (!this.remoteSubscriptions.has(topic)) {
				// no subscriber for this topic
				return;
			}
		}

		this.emit(`.subscription.${topic}`, args);
	}

	register(procedure, endpoint) {
		if (this.localProcedures.has(procedure)) {
			throw new Error(`Procedure '${procedure}' already registered`);
		}
		this.localProcedures.set(procedure, true);
		if (procedure[0] != ".") {
			this.emit(`.procedure<new>.${procedure}`);
		}

		this.on(`.procedure<call>.${procedure}`, async (callNumber, args) => {
			let result;
			try {
				result = endpoint(args);
			}catch (e) {
				this.emit(`.procedure<result>.${procedure}#${callNumber}`, 'error', errorToObject(e));
				return;
			}

			if (result instanceof Object
				&& typeof result.next == 'function'
				&& typeof result.throw == 'function'
				&& (
					typeof result[Symbol.iterator] == 'function'
					|| typeof result[Symbol.asyncIterator] == 'function'
				)
			) {
				// it's generator result

				const resultIterator = this.each(`.procedure<yield>.${procedure}#${callNumber}`);

				let res;
				try {
					res = await result.next();
				}catch (e) {
					this.emit(`.procedure<result>.${procedure}#${callNumber}`, 'error', errorToObject(e));
					return;
				}
				this.emit(`.procedure<result>.${procedure}#${callNumber}`, 'generator', {done: res.done, value: res.value});

				for await (const args of resultIterator) {
					const [yieldValue] = args;
					try {
						res = await result.next(yieldValue);
					}catch (e) {
						this.emit(`.procedure<yield-result>.${procedure}#${callNumber}`, 'error', errorToObject(e));
						return;
					}
					this.emit(`.procedure<yield-result>.${procedure}#${callNumber}`, 'value', {done: res.done, value: res.value});
					if (res.done) {
						break;
					}
				}
			}else {
				if (result instanceof Promise) {
					try {
						result = await result;
					}catch (e) {
						this.emit(`.procedure<result>.${procedure}#${callNumber}`, 'error', errorToObject(e));
						return;
					}
				}
				this.emit(`.procedure<result>.${procedure}#${callNumber}`, 'value', result);
			}
		});
	}

	async call(procedure, args) {
		if (procedure[0] != ".") {
			if (!this.remoteProcedures.has(procedure)) {
				// no registered handler
				throw new Error(`Procedure '${procedure}' is not registered`);
			}
		}

		return new Promise(async (resolve, reject) => {
			const callNumber = this.callNumber++;
			this.once(`.procedure<result>.${procedure}#${callNumber}`).then(async ([resultType, result]) => {
				//console.log({resultType, result});
				if (resultType === 'error') {
					reject(objectToError(result));
				}else if (resultType === 'value') {
					resolve(result);
				}else if (resultType === 'generator') {
					const resultIterator = this.each(`.procedure<yield-result>.${procedure}#${callNumber}`);

					const self = this;
					let yieldValue;

					resolve((async function*() {
						if (result.done) {
							return result.value;
						}else {
							yieldValue = yield result.value;
							self.emit(`.procedure<yield>.${procedure}#${callNumber}`, yieldValue);
						}

						for await (const args of resultIterator) {
							[resultType, result] = args;
							if (resultType === 'error') {
								throw objectToError(result);
							}else if (resultType === 'value') {
								if (result.done) {
									return result.value;
								}else {
									yieldValue = yield result.value;
									self.emit(`.procedure<yield>.${procedure}#${callNumber}`, yieldValue);
								}
							}
						}
					})());
				}
			});

			this.emit(`.procedure<call>.${procedure}`, callNumber, args);
		});
	}

	isProcedureRegisteredRemotely(procedure) {
		return this.remoteProcedures.has(procedure);
	}
	isProcedureRegisteredLocaly(procedure) {
		return this.localProcedures.has(procedure);
	}
	triggerRemoteProcedureRegistered(procedure) {
		this.eventsEmitter.emit(`.procedure<registed>.${procedure}`);
	}
	onRemoteProcedureRegistered(procedure, handler) {
		if (this.isProcedureRegisteredRemotely(procedure)) {
			handler();
		}else {
			this.eventsEmitter.on(`.procedure<registed>.${procedure}`, handler);
		}
	}

	isTopicSubcribedRemotely(topic) {
		return this.remoteSubscriptions.has(topic);
	}
	isTopicSubcribedLocaly(topic) {
		return this.localSubscriptions.has(topic);
	}

	onConnected(handler) {
		this.internalEventsEmitter.on(`.connected`, handler);
	}
	triggerOnConnected() {
		this.internalEventsEmitter.emit(`.connected`);
	}
}

if (typeof module !== 'undefined') {
	module.exports = {
		RACProxy,
	};
}
