<template>
	<router-view></router-view>
</template>

<script lang="ts" setup>
</script>

<style lang="less">
html, body {
	margin: 0;
	padding: 0;
	font-family: Avenir, Helvetica, Arial, sans-serif;
	color: #2c3e50;
	min-height: 100vh;
}

#app {
	min-height: 100vh;
}
</style>
