import { createApp } from 'vue'
import App from './App.vue'

import "@fortawesome/fontawesome-free/js/all.js";

import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';

import * as HomeView from '@/components/HomeView.vue';
import * as NotFound from '@/components/NotFound.vue';
import * as DevView from '@/components/DevView.vue';
import * as LectorCodeViewer from '@/components/LectorCodeViewer.vue';
import * as MonacoEditorTester from '@/components/MonacoEditorTester.vue';


const routes:Readonly<RouteRecordRaw[]> = [
	{ path: '/', component: HomeView.default },
	{ path: '/dev', component: DevView.default },
	{ path: '/view/:playbackSessionId(.+)', component: LectorCodeViewer.default, props: (route) => {
		return {
			debug: route.query.debug !== undefined ? true : false,
			playbackSessionId: route.params.playbackSessionId,
		};
	}},
	{ path: '/test/MonacoEditorTester', component: MonacoEditorTester.default },
	{ path: '/:pathMatch(.*)*', component: NotFound.default },
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

const app = createApp(App);
app.use(router);

app.mount('#app')
