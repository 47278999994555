class EventEmitter {
	listeners = {};

	constructor() {

	}

	on(event, handler, once = false) {
		if (!(event in this.listeners)) {
			this.listeners[event] = [];
		}
		this.listeners[event].push({handler, once});
	}

	once(event, handler) {
		this.on(event, handler, true);
	}

	each(event) {
		function createEventsBucket() {
			const pendingEvents = [];
			let nextResolve = null;

			async function *bucket() {
				while (true) {
					yield new Promise((resolve) => {
						if (pendingEvents.length > 0) {
							return resolve(pendingEvents.shift());
						}

						nextResolve = resolve;
					});
				}
			}

			const iterate = bucket();

			iterate.push = (args) => {
				if (nextResolve !== null) {
					nextResolve(args);
					nextResolve = null;
					return;
				}

				pendingEvents.push(args);
			}

			return iterate;
		}

		const eventsBucket = createEventsBucket();

		this.on(event, (...args) => {
			eventsBucket.push(args);
		});

		return eventsBucket;
	}

	emit(event, ...args) {
		if (event in this.listeners) {
			for (let i = 0; i < this.listeners[event].length; ) {
				const {handler, once} = this.listeners[event][i];
				if (once) {
					this.listeners[event].splice(i, 1);
				}else {
					++i;
				}
				handler(...args);
			}
		}
	}

	removeAllListeners() {
		this.listeners = {};
	}
}

if (typeof module !== 'undefined') {
	module.exports = {
		EventEmitter,
	};
}
