import * as monaco from 'monaco-editor';
import DiffMatchPatch from 'diff-match-patch';


export type EditsBatch = {
	editsList: Array<monaco.editor.IIdentifiedSingleEditOperation[]>
}

export const themeData:monaco.editor.IStandaloneThemeData = {
	"inherit": true,
	"base": "vs-dark",
	"rules": [
		{ token: '', foreground: 'D4D4D4', background: '1E1E1E' },
		{ token: 'invalid', foreground: 'f44747' },
		{ token: 'emphasis', fontStyle: 'italic' },
		{ token: 'strong', fontStyle: 'bold' },
		{ token: 'variable', foreground: '74B0DF' },
		{ token: 'variable.predefined', foreground: '4864AA' },
		{ token: 'variable.parameter', foreground: '9CDCFE' },
		{ token: 'constant', foreground: '569CD6' },
		{ token: 'comment', foreground: '608B4E' },
		{ token: 'number', foreground: 'B5CEA8' },
		{ token: 'number.hex', foreground: '5BB498' },
		{ token: 'regexp', foreground: 'B46695' },
		{ token: 'annotation', foreground: 'cc6666' },
		{ token: 'type', foreground: '3DC9B0' },
		{ token: 'delimiter', foreground: 'DCDCDC' },
		{ token: 'delimiter.html', foreground: '808080' },
		{ token: 'delimiter.xml', foreground: '808080' },
		{ token: 'tag', foreground: '569CD6' },
		{ token: 'tag.id.pug', foreground: '4F76AC' },
		{ token: 'tag.class.pug', foreground: '4F76AC' },
		{ token: 'meta.scss', foreground: 'A79873' },
		{ token: 'meta.tag', foreground: 'CE9178' },
		{ token: 'metatag', foreground: 'DD6A6F' },
		{ token: 'metatag.content.html', foreground: '9CDCFE' },
		{ token: 'metatag.html', foreground: '569CD6' },
		{ token: 'metatag.xml', foreground: '569CD6' },
		{ token: 'metatag.php', fontStyle: 'bold' },
		{ token: 'key', foreground: '9CDCFE' },
		{ token: 'string.key.json', foreground: '9CDCFE' },
		{ token: 'string.value.json', foreground: 'CE9178' },
		{ token: 'attribute.name', foreground: '9CDCFE' },
		{ token: 'attribute.value', foreground: 'CE9178' },
		{ token: 'attribute.value.number.css', foreground: 'B5CEA8' },
		{ token: 'attribute.value.unit.css', foreground: 'B5CEA8' },
		{ token: 'attribute.value.hex.css', foreground: 'D4D4D4' },
		{ token: 'string', foreground: 'CE9178' },
		{ token: 'string.sql', foreground: 'FF0000' },
		{ token: 'keyword', foreground: '569CD6' },
		{ token: 'keyword.flow', foreground: 'C586C0' },
		{ token: 'keyword.json', foreground: 'CE9178' },
		{ token: 'keyword.flow.scss', foreground: '569CD6' },
		{ token: 'operator.scss', foreground: '909090' },
		{ token: 'operator.sql', foreground: '778899' },
		{ token: 'operator.swift', foreground: '909090' },
		{ token: 'predefined.sql', foreground: 'FF00FF' },

		{
			"foreground": "#DCDCAA",
			"token": "entity.name.function"
		},
		{
			"foreground": "#DCDCAA",
			"token": "support.function"
		},
		{
			"foreground": "#DCDCAA",
			"token": "support.constant.handlebars"
		},
		{
			"foreground": "#DCDCAA",
			"token": "source.powershell variable.other.member"
		},
		{
			"foreground": "#DCDCAA",
			"token": "entity.name.operator.custom-literal"
		},
		{
			"foreground": "#4EC9B0",
			"token": "meta.return-type"
		},
		{
			"foreground": "#4EC9B0",
			"token": "support.class"
		},
		{
			"foreground": "#4EC9B0",
			"token": "support.type"
		},
		{
			"foreground": "#4EC9B0",
			"token": "entity.name.type"
		},
		{
			"foreground": "#4EC9B0",
			"token": "entity.name.namespace"
		},
		{
			"foreground": "#4EC9B0",
			"token": "entity.other.attribute"
		},
		{
			"foreground": "#4EC9B0",
			"token": "entity.name.scope-resolution"
		},
		{
			"foreground": "#4EC9B0",
			"token": "entity.name.class"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.numeric.go"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.byte.go"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.boolean.go"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.string.go"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.uintptr.go"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.error.go"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.rune.go"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.cs"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.generic.cs"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.modifier.cs"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.variable.cs"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.annotation.java"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.generic.java"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.java"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.object.array.java"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.primitive.array.java"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.primitive.java"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.token.java"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.groovy"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.annotation.groovy"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.parameters.groovy"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.generic.groovy"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.object.array.groovy"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.primitive.array.groovy"
		},
		{
			"foreground": "#4EC9B0",
			"token": "storage.type.primitive.groovy"
		},
		{
			"foreground": "#4EC9B0",
			"token": "meta.type.cast.expr"
		},
		{
			"foreground": "#4EC9B0",
			"token": "meta.type.new.expr"
		},
		{
			"foreground": "#4EC9B0",
			"token": "support.constant.math"
		},
		{
			"foreground": "#4EC9B0",
			"token": "support.constant.dom"
		},
		{
			"foreground": "#4EC9B0",
			"token": "support.constant.json"
		},
		{
			"foreground": "#4EC9B0",
			"token": "entity.other.inherited-class"
		},
		{
			"foreground": "#C586C0",
			"token": "keyword.control"
		},
		{
			"foreground": "#C586C0",
			"token": "source.cpp keyword.operator.new"
		},
		{
			"foreground": "#C586C0",
			"token": "keyword.operator.delete"
		},
		{
			"foreground": "#C586C0",
			"token": "keyword.other.using"
		},
		{
			"foreground": "#C586C0",
			"token": "keyword.other.operator"
		},
		{
			"foreground": "#C586C0",
			"token": "entity.name.operator"
		},
		{
			"foreground": "#9CDCFE",
			"token": "variable"
		},
		{
			"foreground": "#9CDCFE",
			"token": "meta.definition.variable.name"
		},
		{
			"foreground": "#9CDCFE",
			"token": "support.variable"
		},
		{
			"foreground": "#9CDCFE",
			"token": "entity.name.variable"
		},
		{
			"foreground": "#4FC1FF",
			"token": "variable.other.constant"
		},
		{
			"foreground": "#4FC1FF",
			"token": "variable.other.enummember"
		},
		{
			"foreground": "#9CDCFE",
			"token": "meta.object-literal.key"
		},
		{
			"foreground": "#CE9178",
			"token": "support.constant.property-value"
		},
		{
			"foreground": "#CE9178",
			"token": "support.constant.font-name"
		},
		{
			"foreground": "#CE9178",
			"token": "support.constant.media-type"
		},
		{
			"foreground": "#CE9178",
			"token": "support.constant.media"
		},
		{
			"foreground": "#CE9178",
			"token": "constant.other.color.rgb-value"
		},
		{
			"foreground": "#CE9178",
			"token": "constant.other.rgb-value"
		},
		{
			"foreground": "#CE9178",
			"token": "support.constant.color"
		},
		{
			"foreground": "#CE9178",
			"token": "punctuation.definition.group.regexp"
		},
		{
			"foreground": "#CE9178",
			"token": "punctuation.definition.group.assertion.regexp"
		},
		{
			"foreground": "#CE9178",
			"token": "punctuation.definition.character-class.regexp"
		},
		{
			"foreground": "#CE9178",
			"token": "punctuation.character.set.begin.regexp"
		},
		{
			"foreground": "#CE9178",
			"token": "punctuation.character.set.end.regexp"
		},
		{
			"foreground": "#CE9178",
			"token": "keyword.operator.negation.regexp"
		},
		{
			"foreground": "#CE9178",
			"token": "support.other.parenthesis.regexp"
		},
		{
			"foreground": "#d16969",
			"token": "constant.character.character-class.regexp"
		},
		{
			"foreground": "#d16969",
			"token": "constant.other.character-class.set.regexp"
		},
		{
			"foreground": "#d16969",
			"token": "constant.other.character-class.regexp"
		},
		{
			"foreground": "#d16969",
			"token": "constant.character.set.regexp"
		},
		{
			"foreground": "#DCDCAA",
			"token": "keyword.operator.or.regexp"
		},
		{
			"foreground": "#DCDCAA",
			"token": "keyword.control.anchor.regexp"
		},
		{
			"foreground": "#d7ba7d",
			"token": "keyword.operator.quantifier.regexp"
		},
		{
			"foreground": "#569cd6",
			"token": "constant.character"
		},
		{
			"foreground": "#d7ba7d",
			"token": "constant.character.escape"
		},
		{
			"foreground": "#C8C8C8",
			"token": "entity.name.label"
		},
		{
			"foreground": "#569CD6",
			"token": "constant.language"
		},
		{
			"foreground": "#569CD6",
			"token": "entity.name.tag"
		},
		{
			"foreground": "#569cd6",
			"token": "storage"
		},
		{
			"foreground": "#ff0000",
			"token": "constant"
		},
	],
	"encodedTokensColors": [],
	"colors" : {
		'editor.background': '#1E1E1E',
		'editor.foreground': '#D4D4D4',
		'editorIndentGuide.background': '#404040',
		'editorIndentGuide.activeBackground': '#707070',
		'editor.selectionBackground': '#4269a3',
		'editor.inactiveSelectionBackground': '#4269a344',
		'editor.selectionHighlightBackground': '#00000000',
		'editor.wordHighlightBackground' : '#00000000',
		'editor.wordHighlightStrongBackground' : '#00000000',
	},
};


export function getContentChangesEditorEdits(originalText:string, modifiedText:string) : EditsBatch {
	const editsBatch:EditsBatch = {
		editsList: [],
	};

	//console.log({originalText, modifiedText});

	const dmp = new DiffMatchPatch.diff_match_patch();
	// ziskat vsechny nutne zmeny
	// vysledek je pole casti textu, ktere je bud potreba odebrat, pridat nebo ponechat
	const diffs = dmp.diff_main(originalText, modifiedText);
	// trochu zoptimalizovat diffy, aby jich nebylo tolik
	dmp.diff_cleanupEfficiency(diffs);

	//console.log(diffs);

	let editorPos = {
		line: 1,
		column: 1,
	};

	const getNextEditorPosition = (text:string) => {
		// vzit aktualni pozici
		const pos = {
			...editorPos
		};

		// projit pismenko po pismenku
		for (const c of text) {
			// pokud je to znak odradkovani, tak skocit na zacatek dalsi radky
			if (c == '\n') {
				pos.line++;
				pos.column = 1;
			
			// pokud to je obycejny znak, tak se jen posunout v ramci aktualni radky
			}else {
				pos.column++;
			}
		}

		return pos;
	};

	const edits:monaco.editor.IIdentifiedSingleEditOperation[] = [];

	//debugger;

	// projit vsechny diffy a postupne poskladat editace, ktery je potreba udelat v editoru
	for (const diff of diffs) {
		const type = diff[0];
		const text = diff[1];

		if (type == DiffMatchPatch.DIFF_EQUAL) {
			// ziskat pozici kde konci dany text v editoru
			const nextEditorPos = getNextEditorPosition(text);
			// aktualizovat aktualni pozici v editoru
			editorPos = nextEditorPos;
		}else if (type == DiffMatchPatch.DIFF_DELETE) {
			// ziskat pozici kde konci dany text v editoru
			const nextEditorPos = getNextEditorPosition(text);

			const range:monaco.IRange = {
				startLineNumber: editorPos.line,
				startColumn: editorPos.column,
				endLineNumber: nextEditorPos.line,
				endColumn: nextEditorPos.column,
			};

			const edit:monaco.editor.IIdentifiedSingleEditOperation = {
				forceMoveMarkers: true,
				range: range,
				text: "",
			};
			edits.push(edit);

			// aktualizovat aktualni pozici v editoru
			editorPos = nextEditorPos;
		}else if (type == DiffMatchPatch.DIFF_INSERT) {
			const range:monaco.IRange = {
				startLineNumber: editorPos.line,
				startColumn: editorPos.column,
				endLineNumber: editorPos.line,
				endColumn: editorPos.column,
			};

			const edit:monaco.editor.IIdentifiedSingleEditOperation = {
				forceMoveMarkers: true,
				range: range,
				text: text,
			};
			edits.push(edit);

			// pozici v editoru ponechat - pri vkladani se nezmeni
		}
	}

	editsBatch.editsList.push(edits);

	//console.log(editsBatch.editsList);

	return editsBatch;
}


export function setEditorContent(monacoEditor:monaco.editor.IStandaloneCodeEditor, newContent:string) {
	const model = monacoEditor.getModel();

	let setNewContentByValue = true;

	if (model) {
		const currentContent = monacoEditor.getValue();

		const editsBatch = getContentChangesEditorEdits(currentContent, newContent);

		if (editsBatch.editsList.length) {
			const totalEditsCount = editsBatch.editsList.reduce((partialSum, editList) => partialSum + editList.length, 0);
			// provest pomoci diffu pouze pokud pocet bloku ke zmene neni prilis hodne
			// Ono se to pak chova divne, a napr. tam zustava color picker
			if (totalEditsCount <= 2) {
				// provest seznam editaci
				for (const edits of editsBatch.editsList) {
					model.applyEdits(edits);
				}
				// na zaver provest kontrolu zdali opravdu je v editoru co by tam melo byt
				if (monacoEditor.getValue() != newContent) {
					//alert("Applying content patch failed");
				}else {
					// OK, neaplikovat pomoci setValue
					setNewContentByValue = false;
				}
			}
		}
	}

	if (setNewContentByValue) {
		monacoEditor.setValue(newContent);
	}
}