class CommunicationConnection {
	dataReceiverHandler = null;
	connectionOpenHandler = null;

	constructor() {
		this.dataReceiverHandler = null;
		this.connectionOpenHandler = null;
	}
	setDataReceiverHandler(dataReceiverHandler) {
		this.dataReceiverHandler = dataReceiverHandler;
	}
	onReceivedData(data) {
		if (this.dataReceiverHandler === null) {
			throw new Error('Data receive handler is not set');
		}
		this.dataReceiverHandler(data);
	}
	setConnectionOpenHandler(connectionOpenHandler) {
		this.connectionOpenHandler = connectionOpenHandler;
	}
	onConnectionOpen() {
		if (this.connectionOpenHandler !== null) {
			this.connectionOpenHandler();
		}
	}
	send(data) {
		throw new Error('Not implemented');
	}
}
class WebsocketCommunicationConnection extends CommunicationConnection {
	constructor(webSocketConnection) {
		super();
		this.webSocketConnection = webSocketConnection;
		this.webSocketConnection.addEventListener('message', (messageRaw) => {
			this.onReceivedData(messageRaw.data);
		});
		this.webSocketConnection.addEventListener('open', () => {
			this.onConnectionOpen();
		});
	}
	send(data) {
		this.webSocketConnection.send(data);
	}
}
class FakeCommunicationConnection extends CommunicationConnection {
	constructor(debug) {
		super();
		this.debug = debug;
	}
	send(data) {
		if (this.debug) {
			console.log('~~~~', data);
		}
		this.onReceivedData(data);
	}
}

if (typeof module !== 'undefined') {
	module.exports = {
		CommunicationConnection,
		WebsocketCommunicationConnection,
		FakeCommunicationConnection,
	};
}
