export interface IAppConfig {
	api: {
		webUrl:string,
		websocketUrl:string,
	},
}
export async function loadConfig():Promise<IAppConfig> {
	const res = await fetch("/config/config.json", {cache: "no-store"});
	return await res.json();
}
